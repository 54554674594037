// import { useMemo } from 'react'
// import { Install } from '@pages/Install'
// import { App } from '@pages/App'
import { Cloak } from '@pages/Cloak'
import './global.css'

export const StartUp = () => {
  return <Cloak />
}

//export const StartUp = () => {
//   const isPWAInstalled = window.matchMedia('(display-mode: standalone)').matches
//
//   const isBot = useMemo(() => {
//     const userAgent = navigator.userAgent.toLowerCase()
//
//     return /bot|crawler|spider|googlebot|yandexbot|bingbot|baiduspider|twitterbot|facebookexternalhit|linkedinbot|pinterestbot|redditbot|vkbottle|whatsappbot|telegrambot/i.test(
//       userAgent
//     )
//   }, [])
//
//   if (isBot) return <Cloak />
//   if (!isPWAInstalled) return <Install />
//
//   return (
//     <>
//       {!isPWAInstalled && <Install />}
//       <App isDisplay={isPWAInstalled} />
//     </>
//   )
// }
